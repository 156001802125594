<template>
    <div class="searchbar">
      <!-- <div class="text">小明早阿～您現在想要 ΣCOUT 的 </div> -->
      <div class="text">找 </div>
      <el-select v-model="syncModuleType" placeholder="請選擇" class="sa-select sa-select--icon sa-select--normal" style="width: 160px;">
        <!-- <div slot="prefix">
          <div><font-awesome-icon :icon="['fas', keywordTypeIcon]"/></div>
        </div> -->
        <el-option
          v-for="item in moduleTypeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <!-- <div class="text">是</div> -->
      <label
        class="sa-input-set"
        style="max-width:180px;margin-left:12px"
        :style="inputStyle">
        <!-- <el-autocomplete
          ref="keywordInput"
          class="sa-input sa-input--normal sa-input--append-circle"
          placeholder="輸入關鍵字搜尋"
          :trigger-on-focus="false"
          :fetch-suggestions="autocomplete"
          :debounce="300"
          @select="handleSelectAutocomplete"
          @keypress.enter="search">
        </el-autocomplete> -->
        <el-input
          v-model="syncKeyword"
          class="sa-input sa-input--normal sa-input--append-circle"
          placeholder="輸入關鍵字搜尋"
          @keypress.enter="handleKeywordInputEnter">
        </el-input>
        <div class="sa-input-append">
          <el-button @click="search">
            <font-awesome-icon style="color:#131417" :icon="['fas', 'search']"/>
          </el-button>
        </div>
      </label>
    </div>
</template>


<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
// import companyKeywordTypeOptions from '@/const/companyKeywordTypeOptions.js'

export default {
  data () {
    return {
      // companyKeywordTypeOptions,
      // searchData: {
      //   keyword: '',
      //   keyword_type: companyKeywordTypeOptions[0].value
      // },
      // cat: 1,
      // searchIput: '',
      isPageLoading: false,
      moduleTypeOptions: [
        {
          label: '所有模組',
          value: 'all'
        },
        {
          label: '圖表(Chart)',
          value: 'chart'
        },
        {
          label: '圖軸(Axis)',
          value: 'axis'
        },
        {
          label: '圖形(Graphic)',
          value: 'graphic'
        },
        {
          label: '標籤(Label)',
          value: 'label'
        },
        {
          label: '拉軸(Slider)',
          value: 'slider'
        },
        {
          label: '文字提示(Tooltip)',
          value: 'tooltip'
        },
        {
          label: '通用模組(Util)',
          value: 'util'
        },
      ]
    }
  },
  computed: {
    ...mapState([
      'keyword',
      'moduleType'
      // 'currentKeyword',
      // 'currentKeywordType'
    ]),
    // ...mapState('companyList', [
    //   'isPageLoading'
    // ]),
    // ...mapGetters([
    //   'getterUserEmail'
    // ]),
    // keywordTypeIcon () {
    //   const item = companyKeywordTypeOptions.find(d => d.value === this.searchData.keyword_type)
    //   if (item) {
    //     return item.icon
    //   }
    //   return companyKeywordTypeOptions[0].icon
    // }
    syncKeyword: {
      get () {
        return this.keyword
      },
      set (k) {
        this.mutationKeyword(k)
      }
    },
    syncModuleType: {
      get () {
        return this.moduleType
      },
      set (t) {
        this.mutationModuleType(t)
      }
    },
    inputStyle () {
      if (this.syncKeyword) {
        return { opacity: 1 }
      }
      return { opacity: 0.7 }
    }
  },
  watch: {
    // 'currentKeyword': {
    //   handler (currentKeyword) {
    //     this.$set(this.searchData, 'keyword', currentKeyword)
    //   },
    //   immediate: true
    // },
    // 'currentKeywordType': {
    //   handler (currentKeywordType) {
    //     this.$set(this.searchData, 'keyword_type', currentKeywordType || companyKeywordTypeOptions[0].value)
    //   },
    //   immediate: true
    // }
  },
  methods: {
    ...mapMutations([
      'mutationKeyword',
      'mutationModuleType'
    ]),
    autocomplete (queryString, cb) {
      // try {
      //   if (this.searchData.keyword_type === 'owner') {
      //     let searchRequest = {
      //       basic: {
      //         get: ['list'],
      //         keyword: queryString,
      //         field: 'representative_name', // @Q@ 因後端命名方式有所差異所以寫死，同owner資料
      //         fetch_less_fields: 1,
      //         'page-size': 10,
      //         'page-index': 1,
      //         'search-label': {
      //           user: this.getterUserEmail,
      //           label: queryString
      //         }
      //       }
      //     }
      //     this.$apis.namesGet(searchRequest).then(data => {
      //       if (!data.payload || !data.payload.list) {
      //         cb([])
      //         return
      //       }
      //       const list = data.payload.list
      //         .slice(0, 10)
      //         .map(d => {
      //           return {
      //             label: d,
      //             value: d
      //           }
      //         })
      //       // -- 回傳值 --
      //       cb(list)
      //     })
      //   } else {
      //     let searchRequest = {
      //       basic: {
      //         get: ['list'],
      //         keyword: queryString,
      //         keyword_type: this.searchData.keyword_type,
      //         fetch_less_fields: 1,
      //         'page-size': 10,
      //         'page-index': 1,
      //         'search-label': {
      //           user: this.getterUserEmail,
      //           label: queryString
      //         }
      //       }
      //     }
      //     this.$apis.companyListGet(searchRequest).then(data => {
      //       if (!data.payload || !data.payload.list) {
      //         cb([])
      //         return
      //       }
      //       const list = data.payload.list
      //         // 過濾重覆的資料
      //         // .reduce((prev, data) => {
      //         //   if (prev.find(d => d.uniID === data.uniID) == null) {
      //         //     prev.push(data)
      //         //   }
      //         //   return prev
      //         // }, [])
      //         .map(d => {
      //           d.value = d.company_name
      //           return d
      //         })
      //       // -- 顯示格式所需資料 --
      //       // if (
      //       //   this.searchData.keyword_type === 'scout'
      //       //   this.searchData.keyword_type === 'all' ||
      //       //   this.searchData.keyword_type === 'company' ||
      //       //   this.searchData.keyword_type === 'registraction'
      //       // ) {
      //         // list = data.payload.list.map(d => {
      //         //   d.value = d.company_name
      //         //   return d
      //         // })
      //       // } else if (this.searchData.keyword_type === 'tax_number') {
      //       //   list = data.payload.list.map(d => {
      //       //     d.value = d.uniID
      //       //     return d
      //       //   })
      //       // }
      //       // -- 回傳值 --
      //       cb(list)
      //     })
      //   }
      // } catch (e) {
      //   console.error(e)
      // }
    },
    handleSelectAutocomplete () {
      this.$refs.keywordInput.focus()
    },
    async search () {
      this.$router.push({
        name: 'demo-list',
        query: {
          keyword: this.keyword,
          moduletype: this.moduleType
          // query
        }
      })

      // // 移除焦點
      // try {
      //   this.$refs.keywordInput.close()
      // } catch (e) {
      //   console.error(e)
      // }
    },
    handleKeywordInputEnter () {
      // this.syncKeyword()
      // if (this.$route.name === 'demo-list') {
      //   // 列表頁只做即時查詢（enter不另執行）
      //   return
      // }
      // if (this.syncKeyword) {
      //   this.$router.push({
      //     name: 'demo-list',
      //     query: {
      //       keyword: this.syncKeyword
      //     }
      //   })
      // } else {
      //   this.$router.push({
      //     name: 'demo-list',
      //     query: {}
      //   })
      // }
    },
    // handleKeywordInputBlur () {
    //   if (this.$route.name !== 'demo-list') {
    //     // 非列表頁不做即時查詢
    //     return
    //   }
    //   if (this.$route.query.keyword && this.syncKeyword === this.$route.query.keyword) {
    //     // 無變更則不執行
    //     return
    //   }
    //   if (this.syncKeyword) {
    //     this.$router.replace({
    //       name: 'demo-list',
    //       query: {
    //         keyword: this.syncKeyword
    //       }
    //     })
    //   } else {
    //     this.$router.replace({
    //       name: 'demo-list',
    //       query: {}
    //     })
    //   }
    // },
  }
}
</script>