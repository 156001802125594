import { defineComponent, computed, watch, ComputedRef, getCurrentInstance } from 'vue';
import { useRoute, useRouter } from 'vue-router/composables';
import { mapMutations, mapState, mapGetters } from 'vuex';
import TheHeader from '@/components/TheHeader.vue';
import TheSearchBar from '@/components/TheSearchBar.vue';
import TheFooter from '@/components/TheFooter.vue';
export default defineComponent({
    components: {
        TheHeader,
        TheSearchBar,
        TheFooter
    },
    setup(_, {}) {
        // @ts-ignore
        const { proxy } = getCurrentInstance();
        const route = useRoute();
        const router = useRouter();
        const isShowTopSearchBar = computed(() => {
            return route.name === 'home' ? false : true;
        });
        const keyword = computed(() => String(proxy.$store.state.keyword || ''));
        const moduleType = computed(() => String(proxy.$store.state.moduleType || ''));
        watch([keyword, moduleType], ([_keyword, _moduleType]) => {
            if (route.name !== 'demo-list') {
                router.push({
                    name: 'demo-list',
                    query: {
                        keyword: _keyword,
                        moduletype: _moduleType,
                        page: '1'
                    }
                });
            }
        });
        return {
            isShowTopSearchBar
        };
    }
});
