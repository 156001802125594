<template>
  <router-view/>
</template>

<script>
import { defineComponent } from 'vue'
import TheHeader from '@/components/TheHeader.vue'
import TheSearchBar from '@/components/TheSearchBar.vue'
import TheFooter from '@/components/TheFooter.vue'

export default defineComponent({
  components: {
    TheHeader,
    TheSearchBar,
    TheFooter
  }
})
</script>

<style lang="scss">
@import '@/assets/scss/base/_core';

.wrapper {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  background-color: $color-black;
  // color: #ffffff;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }

.bp-logo {
  width: 24px;
  height: 24px;
  color: white;
  transform: translate(0px, 5px)
}
.share-btn-icon {
  width: 18px;
  height: 18px;
}
</style>
