<template>
  <header>
    <!-- <div style="position:relative"> -->
      <router-link :to="{ path: '/' }">
        <div style="position:relative">
          <!-- <svg class="logo" width="85" height="32" viewBox="0 0 85 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.83 10.9049H43.6833H42.2537V12.1757V13.9231V15.1939V15.7975H43.6833V15.1939H48.83V15.7975H50.2596V15.1939V13.9231V12.1757V10.9049H48.83ZM48.83 13.9231H43.6833V12.1757H48.83V13.9231Z" />
            <path d="M50.9585 8.71284H46.9873V7.34675H50.3867L49.8148 6.01242H46.9873V4.55102H45.4942V6.04419H43.6515L44.1916 4.93225L44.1598 4.83694H42.6984L41.1099 7.95037L41.1417 8.01391L42.6984 7.98214L43.0161 7.34675H45.4942V8.71284H40.8876V10.1425H51.5304L50.9585 8.71284Z" />
            <path d="M29.7046 6.29833L37.7424 6.36187L37.6471 7.41027L38.759 7.44204V5.9171V5.31347H34.5654L34.8513 4.29684H34.2159H33.0405H32.3733L32.691 5.31347H28.4656V7.31496H29.5776L29.7046 6.29833Z"/>
            <path d="M37.0434 7.79152L37.1387 6.8702L30.3083 6.80666L30.1812 7.72798L28.1479 7.69621V8.71284L30.0541 8.74461L29.927 9.6977L36.8846 9.76124L36.9799 8.80815L39.172 8.83991V7.82329L37.0434 7.79152ZM32.8498 8.99876H31.2614L31.3249 8.61753H32.9134L32.8498 8.99876ZM32.9769 7.91859H31.3884L31.452 7.56913H33.0087L32.9769 7.91859ZM35.582 9.03053H34.0253L34.0889 8.6493H35.6456L35.582 9.03053ZM35.7091 7.95036H34.1842L34.2159 7.6009H35.7409L35.7091 7.95036Z" />
            <path d="M36.0903 14.1772H37.52L37.5518 10.2695L29.6729 10.206L29.6411 14.1137H30.8801L29.0375 14.9714V15.035L30.0223 15.7657L32.5957 14.5267L32.6275 14.4631L32.1509 14.0819L34.8831 14.1137L34.343 14.5267L34.3112 14.622L36.9481 15.7975L38.1236 15.035V14.9714L36.0903 14.1772ZM36.0586 13.4465L31.039 13.4147V13.0335L36.0586 13.0653V13.4465ZM36.0586 12.3981L31.039 12.3663V11.9851L36.0586 12.0169V12.3981ZM36.0903 11.3179L31.0707 11.2862V10.9367L36.0903 10.9685V11.3179Z"/>
            <path class="third" d="M66.4304 31.9682H83.9355C84.4438 31.9682 84.8886 31.5552 84.8886 31.0151V5.21817C84.8886 4.70985 84.4756 4.26508 83.9355 4.26508H66.1762L80.0596 17.8625C80.409 18.212 80.4408 18.8156 80.0596 19.1651L67.7965 31.6823C67.6059 31.8729 67.3835 31.9682 67.1293 31.9682H66.4304ZM66.4304 31.9682H66.0174H66.4304Z" />
            <path class="secondary1" d="M63.857 31.9682L64.0159 32C63.9841 31.9682 63.9206 31.9682 63.857 31.9682Z"/>
            <path class="secondary2" d="M80.0596 17.8625L62.0779 0.2621C61.7285 -0.0873666 61.1249 -0.0873666 60.7754 0.2621L59.8858 1.18342L76.9144 17.8625C77.2638 18.212 77.2956 18.8156 76.9144 19.1651L64.6513 31.6823C64.4607 31.8729 64.2383 31.9682 63.9841 31.9682H67.1293C67.3835 31.9682 67.6059 31.8729 67.7965 31.6823L80.0596 19.1651C80.4408 18.8156 80.4408 18.212 80.0596 17.8625Z"/>
            <path d="M76.9144 17.8625L58.9328 0.2621C58.5833 -0.0873666 57.9797 -0.0873666 57.6302 0.2621L52.8012 5.1864C52.5471 5.44056 52.4835 5.85356 52.6106 6.20303L63.1899 31.3646C63.317 31.6505 63.5711 31.8412 63.8571 31.9047C63.9206 31.9047 63.9841 31.9365 64.0159 31.9365C64.2701 31.9365 64.4925 31.8412 64.6831 31.6505L76.9462 19.1333C77.2956 18.8156 77.2956 18.212 76.9144 17.8625Z" />
            <path d="M18.6488 28.2512C18.4264 28.5689 18.1087 28.823 17.7275 29.0454C17.3462 29.236 16.9015 29.3314 16.3931 29.3314C15.9484 29.3314 15.5354 29.236 15.1541 29.0772C14.7729 28.9183 14.4552 28.696 14.201 28.41C13.9469 28.1241 13.7245 27.7746 13.5656 27.3616C13.4386 27.0122 13.3433 26.5356 13.3433 26.0591C13.3433 25.5825 13.4068 25.1378 13.5656 24.7565C13.7245 24.3753 13.9151 24.0258 14.201 23.7081C14.487 23.4222 14.8047 23.1998 15.1859 23.0092C15.9166 22.6915 16.8062 22.6597 17.6322 23.0092C18.0134 23.168 18.3311 23.4222 18.5853 23.7399L18.6488 23.8352L20.5867 21.8972L20.5232 21.8337C20.0467 21.2936 19.4113 20.8806 18.6488 20.5947C17.8863 20.3088 17.0921 20.1817 16.3296 20.1817C15.4718 20.1817 14.6776 20.3088 13.9469 20.5947C13.2162 20.8806 12.549 21.2619 12.0089 21.7702C11.4688 22.2785 11.0241 22.9139 10.7064 23.6446C10.3887 24.3753 10.2298 25.2013 10.2298 26.0908C10.2298 26.9486 10.3887 27.7746 10.6746 28.5053C10.9923 29.236 11.4053 29.8714 11.9454 30.3797C12.4855 30.8881 13.1209 31.3011 13.8516 31.587C14.5823 31.8729 15.3765 32 16.2343 32C17.1874 32 18.0134 31.8412 18.7441 31.4917C19.4748 31.174 20.0784 30.6974 20.5867 30.1256L20.6503 30.0621L18.6488 28.1876V28.2512Z" />
            <path d="M33.517 23.6128C33.1993 22.8821 32.7863 22.2785 32.2462 21.7702C31.7061 21.2619 31.039 20.8806 30.3083 20.5947C29.5776 20.3088 28.7516 20.1817 27.8938 20.1817C27.036 20.1817 26.21 20.3088 25.4793 20.5947C24.8439 20.8171 24.2403 21.1665 23.7637 21.5796L25.8287 23.5493C26.0829 23.3269 26.3371 23.1363 26.6548 23.0092C27.036 22.8503 27.449 22.755 27.8938 22.755C28.3386 22.755 28.7516 22.8503 29.1328 23.0092C29.514 23.168 29.8317 23.3904 30.0859 23.6763C30.34 23.9623 30.5624 24.3117 30.7213 24.7247C30.8801 25.1378 30.9437 25.5508 30.9437 26.0273C30.9437 26.5039 30.8801 26.9804 30.7213 27.3616C30.5624 27.7746 30.3718 28.1241 30.0859 28.41C29.8317 28.696 29.4823 28.9183 29.1328 29.109C28.7516 29.2678 28.3386 29.3631 27.8938 29.3631C27.449 29.3631 27.036 29.2678 26.6548 29.109C26.3053 28.9501 25.9876 28.7277 25.7334 28.4736L23.7955 30.5704C24.272 30.9834 24.8439 31.3011 25.4793 31.5552C26.21 31.8412 27.036 31.9682 27.8938 31.9682C28.7516 31.9682 29.5776 31.8094 30.3083 31.5552C31.039 31.2693 31.7061 30.8563 32.2462 30.348C32.7863 29.8397 33.2311 29.2043 33.517 28.4736C33.8347 27.7429 33.9618 26.9169 33.9618 26.0591C33.9618 25.1378 33.8347 24.3117 33.517 23.6128Z" />
            <path class="secondary3" d="M25.6699 28.4418L25.7017 28.4736L23.7637 30.5704C23.6684 30.5068 23.6049 30.4115 23.5096 30.348C22.9695 29.8397 22.5247 29.2043 22.2388 28.4736C21.9211 27.7429 21.794 26.9168 21.794 26.0591C21.794 25.1695 21.9528 24.3435 22.2388 23.6446C22.5247 22.9456 22.9695 22.3102 23.5096 21.8019C23.5731 21.7384 23.6366 21.6749 23.7319 21.6113L25.797 23.581C25.7652 23.6128 25.7017 23.6763 25.6699 23.7081C25.4157 23.994 25.1934 24.3435 25.0345 24.7565C24.8757 25.1695 24.8121 25.5825 24.8121 26.0591C24.8121 26.5356 24.8757 27.0122 25.0345 27.3934C25.1934 27.7746 25.4157 28.1241 25.6699 28.4418Z" />
            <path d="M43.1114 27.2028C43.1114 27.4887 43.0797 27.7746 43.0161 28.0288C42.9526 28.2829 42.8255 28.5053 42.6667 28.7277C42.5078 28.9183 42.3172 29.0772 42.063 29.2043C41.8089 29.3313 41.4912 29.3949 41.1417 29.3949C40.7923 29.3949 40.4746 29.3313 40.2204 29.2043C39.9662 29.0772 39.7756 28.9183 39.6168 28.7277C39.4579 28.5371 39.3626 28.2829 39.2673 28.0288C39.2038 27.7746 39.1402 27.4887 39.1402 27.2028V20.4676H36.3127V27.4252C36.3127 28.0923 36.4398 28.7277 36.6304 29.2678C36.8528 29.8079 37.1705 30.3162 37.5835 30.6974C37.9965 31.0787 38.5048 31.3964 39.0767 31.6187C39.6485 31.8411 40.3475 31.9364 41.0782 31.9364C41.8089 31.9364 42.476 31.8094 43.0479 31.6187C43.6515 31.3964 44.1598 31.0787 44.5728 30.6974C44.9858 30.3162 45.3353 29.8079 45.5577 29.2678C45.7801 28.7277 45.9072 28.0923 45.9072 27.4252V20.4676H43.0797V27.2028H43.1114Z"/>
            <path d="M55.8193 20.4676H47.5592V22.9456H50.6726V31.6823H53.5319V22.9456H56.8677L55.8193 20.4676Z"/>
            <path d="M4.03475 29.5537L7.14818 26.1862H7.17995V25.8685L3.71705 22.6279H8.51427V20.1817H0V22.2785L3.71705 26.0273V26.0591L0 29.9032V31.9682H8.60958V29.5537H4.03475Z" />
          </svg> -->
          <!-- <font-awesome-icon :icon="['fas', 'chart-bar']" size="lg"/> -->
          <!-- <fa icon="chart-bar" type="fas" class="bp-logo"></fa> -->
          <!-- <div style="font-size:14px;color:white;line-height:20px">藍星球圖表</div> -->
          <span style="font-size:24px;color:white;line-height:36px;letter-spacing:2px">
            <i class="el-icon-s-data"/> BPCHART
          </span>
          <span style="font-size:12px;color:#afb0b3;letter-spacing:1px">v{{version}}</span>
          <!-- <i class="el-icon-s-data" style="position:absolute;top:0px;left:60px;font-size:24px;"></i> -->
        </div>
      </router-link>
      <nav>
        <ul>
          <li :class="{active:$route.name=='demo-list'}">
            <router-link :to="{ name: 'demo-list' }">圖表</router-link>
          </li>
          <li :class="{active:$route.name=='guide'}">
            <router-link :to="{ name: 'guide' }">指南</router-link>
          </li>
          <li :class="{active:$route.name=='versions'}">
            <router-link :to="{ name: 'versions' }">版本</router-link>
          </li>
          <li :class="{active:$route.name=='versions'}">
            <a href="http://192.168.1.156:3000/" target="_blank">v3.alpha</a>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'developer' }">開發者文件</router-link>
          </li> -->
          <!-- <li>
            <el-dropdown
              trigger="click"
              @command="handleDropdownClick">
              <button class="user-nav-btn">
                <div class="user-avatar">
                  <img src="@/assets/images/default-avatar.svg"/>
                </div>
                <div class="user-name">{{userName}}</div>
                <font-awesome-icon :icon="['fas', 'chevron-down']"/>
              </button>
              <el-dropdown-menu
                class="user-nav-dropdown-menu"
                v-if="isMeetTaipeiAccount">              
                <el-dropdown-item command="logout"><font-awesome-icon :icon="['fas', 'sign-out-alt']"/> 登出</el-dropdown-item>
              </el-dropdown-menu>
              <el-dropdown-menu
                class="user-nav-dropdown-menu"
                v-else>
                <el-dropdown-item>
                  <nuxt-link :to="{name:'user'}"><font-awesome-icon :icon="['fas', 'user']"/> 個人檔案</nuxt-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <nuxt-link :to="{name:'list',query:{ type: 'collections' }}">
                    <font-awesome-icon :icon="['fa', 'bookmark']"/> 我的收藏
                  </nuxt-link>
                </el-dropdown-item>
                <el-dropdown-item v-if="getterIsSaManager">
                  <nuxt-link :to="{name:'samanager'}"><font-awesome-icon :icon="['fas', 'cog']"/> 管理者功能</nuxt-link>
                </el-dropdown-item>
                <el-dropdown-item command="report">
                  <font-awesome-icon :icon="['fa', 'question']"/> 問題回報
                </el-dropdown-item>
                <el-dropdown-item command="logout">
                  <font-awesome-icon :icon="['fas', 'sign-out-alt']"/> 登出
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li> -->
        </ul>
      </nav>
    <!-- </div> -->
  </header>
</template>

<script>
// import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import d3ModulesPackage from '@/packages/d3-modules/package.json'

export default {
  data () { 
    return {
      // dialogVisible: false,
      // // loginForm: {
      // //   account: '',
      // //   psw: '',
      // // },
      // formComponent: 'TheLoginForm'
    }
  },
  computed: {
    // ...mapState([
    //   'isLogin',
    //   'userInfo',
    //   'isMeetTaipeiAccount'
    // ]),
    // ...mapGetters([
    //   'getterIsSaManager'
    // ]),
    // userName () {
    //   return (this.userInfo && this.userInfo.name) ? this.userInfo.name : ''
    // }
    version () {
      return d3ModulesPackage && d3ModulesPackage.version
    }
  },
  methods: {
    // ...mapActions([
    //   'actionLogout'
    // ]),
    // ...mapMutations([
    //   'mutationReportLightbox',
    //   'mutationIsFullPageLoading',
    // ]),
    async handleDropdownClick (command) {
      // if (command === 'report') {
      //   this.mutationReportLightbox(true)
      // } else if (command === 'logout') {
      //   this.mutationIsFullPageLoading(true)
      //   try {
      //     await this.actionLogout()
      //   } catch (e) { console.error(e) }
      //   window.location.href = '/'
      //   // this.$router.push({ name: 'index' })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  color: white;
}
ul li.active {
  border-bottom: white solid 2px;
  margin-bottom: -2px; // 修正border-bottom的位移
}
</style>